
import { defineComponent, ref, onMounted, reactive, toRefs } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex'
import { KufriActiveProps } from './types'
import { getKufriActiveData, runKufriActive, activeKufriActive } from '@/API/sysParam'
import { tableColumnsConf } from './tableColumns';
import { getMonth } from '@/utils'

export default defineComponent({
    name: '',
    setup() {
        const router = useRouter() 
        const { back } = router;
        const store = useStore()
        const make = store.state.make;
        // 当前月
        const currentMonth = getMonth();
        let fourResult: boolean | null = null;
        const isVANOrDENZA: boolean = (make === 'VAN') || (make === 'DENZA')

        // 设置初始化数据变量
        const dataSource = reactive<KufriActiveProps>({
            id: '',
            active: true,
            versionList: [],
            kufriActiveSearchTableDtoList: [],
            yearList: [],
        })  

        const activeBool = ref(false)

        const toActiveBool = (data: KufriActiveProps) => {
            if (data.kufriActiveSearchTableDtoList.slice(0,3).every(item => {
                return item.result
            })) {
                activeBool.value = true
            } else {
                activeBool.value = false
            }
            if (data.kufriActiveSearchTableDtoList.every(item => {
                return item.result
            })) {
                activeBool.value = false
            }
            
        }

        const versionValue = ref()
        const yearValue = ref() 
        // 处理接口返回的数据
        const getData = (data: KufriActiveProps) => {
            dataSource.versionList = data.versionList;
            dataSource.yearList = data.yearList;
            dataSource.kufriActiveSearchTableDtoList = data.kufriActiveSearchTableDtoList;
            versionValue.value = versionValue.value || (data.kufriActiveSearchTableDtoList.length > 0 ? data.versionList[currentMonth] : '')
            yearValue.value = yearValue.value || (data.yearList.length > 0 ? data.yearList[0] : undefined)
            // 获取第四部状态
            fourResult = dataSource.kufriActiveSearchTableDtoList[3].result;

            toActiveBool(data)
        }

        // 搜索
        const handlerSearch = () => {
            const params = {
                make: make,
                version: versionValue.value || '',
                year: yearValue.value || 0,
            }
            getKufriActiveData({params}).then(res => {
                getData(res)
            })
        }

        // run
        const handleRun = () => {
            const params = {
                make: make,
                version: versionValue.value || '',
                year: yearValue.value || 0,
            }
            /**
             * 先注释  make选择VAN的时候 可以激活
            */
            // if (make === 'VAN') {

                runKufriActive({params}).then(res => {     
                    if (res.code === '0') {
                        res.data && getData(res.data)
                    } 
                    // fourResult不为true跳转到KufriDataDetail
                    if ( !fourResult && isVANOrDENZA ) {
                        router.push({ 
                            name: 'KufriDataDetail',
                            query: {
                                version: versionValue.value,
                                year: yearValue.value,
                            }
                        });
                    } 
                }).catch(() => {
                    if ( !fourResult && isVANOrDENZA ) {
                        router.push({ 
                            name: 'KufriDataDetail',
                            query: {
                                version: versionValue.value,
                                year: yearValue.value,
                            }
                        });
                    }    
                })
            // }

        }

        // Active
        const handleActive = () => {
            const params = {
                make: make,
                version: versionValue.value || '',
                year: yearValue.value || 0,
            }
             /**
             * 先注释 如果第五行数据的result是true就不调用接口
            */
        //    if (dataSource.kufriActiveSearchTableDtoList[4].result) {
        //        return
        //    }
            activeKufriActive({params}).then(res => {
                if (res.code === '0') {
                    res.data && getData(res.data) // fourResult为true的时候刷新页面
                } 
            })
        }

        // 获取数据
        const initKufriActiveData = () => {            
            handlerSearch()
        }

        const goDetail = (index: number, record: any) => {
            switch (index) {
                case 0:
                    router.push({ 
                        name: 'KufriDataDetail',
                        query: {
                            version: versionValue.value,
                            year: yearValue.value,
                        }
                    });
                    break;
                case 1:
                    router.push({ 
                        name: 'NstGroupMapping', 
                        query: {
                            version: versionValue.value,
                            year: yearValue.value,
                        }
                    });
                    break;
                case 2:
                    router.push({ 
                        path: '/updateMSRP',
                        query: {
                            version: versionValue.value,
                            year: yearValue.value,
                        }
                    });
                    break;
                default:
                    break;
            }
        }

        //离开当前的组件，触发
        onBeforeRouteLeave((to)=>{
            const params = {
                version: versionValue.value,
                year: yearValue.value,
            }
            const routerName = ['KufriDataDetail', 'NstGroupMapping', 'UpdateMSRP']
            if (to.name && routerName.includes(to.name as string)) {
                sessionStorage.setItem('kufriActiveParams', JSON.stringify(params))
            } else {
                sessionStorage.removeItem('kufriActiveParams')
            }
        })
      
        onMounted(() => {
            if (sessionStorage.getItem('kufriActiveParams'))  {
                const data = JSON.parse(sessionStorage.getItem('kufriActiveParams') as string)
                versionValue.value = data.version
                yearValue.value = data.year
            }
            initKufriActiveData()
        })

        return {
            // 导出文件
            handlerSearch,
            handleRun,
            handleActive,
            back,
            goDetail,

            ...toRefs(dataSource),

            versionValue,
            yearValue,
            tableColumnsConf,
            activeBool,
        }

    }
 });
