const tableColumnsConf = [
    { 
        title: 'Step', 
        dataIndex: 'stepName' 
    },
    { 
        title: 'Comments', 
        dataIndex: 'comments', 
        slots: { customRender: 'comments' },
    },
    { 
        title: 'Result', 
        dataIndex: 'address', 
        width: 150,
        align: 'center',
        slots: { customRender: 'result' },
    },
    {
        title: 'Operation',
        fixed: 'right',
        width: 150,
        slots: { customRender: 'operation' },
    },
];

export { tableColumnsConf };